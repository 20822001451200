import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./hero.css";
import heroBg from "./HerorightBg.png";
const Hero = () => {
  return (
    <div className="hero-main-wrap">
      <Container>
        <div className="hero-inner-wrap">
          <div className="hero-slider-items">
            <div className="hero-slider-single-item">
              <Row>
                <Col lg={7}>
                  <div
                    style={{ backgroundImage: `url(${heroBg})` }}
                    className="hero-left-image"
                  >
                    {/* <img src={heroBg} alt="" /> */}
                    <div className="hero-content-left">
                      <h2>Want to become a subject matter expert ?</h2>
                      <p>Learn key insights</p>
                    </div>
                  </div>
                </Col>
                <Col lg={5} className="my-auto">
                  <div className="hero-slider-content-right">
                    <h3>ACADEMIC AREAS</h3>
                    <div className="academic-right-area-wrap">
                      <div className="academic-right-single-item">
                        <a href="/">ECONOMIC ENVIRONMENT & PUBLIC AFFAIRS </a>
                        <a href="/">STRATEGY</a>
                      </div>{" "}
                      <div className="academic-right-single-item">
                        <a href="/">ENTREPRENEURSHIP</a>
                        <a href="/">DIGITAL TECHNOLOGIES & DATA SCIENCE</a>
                      </div>{" "}
                      <div className="academic-right-single-item">
                        <a href="/">MARKETING & COMMUNICATIONS</a>
                        <a href="/">FINANCE</a>
                      </div>{" "}
                      <div className="academic-right-single-item">
                        <a href="/">HUMAN RESOURCES</a>
                        <a href="/">FINANCIAL ACCOUNTING</a>
                      </div>{" "}
                      <div className="academic-right-single-item">
                        <a href="/">OPERATIONS & SUPPLY CHAIN MANAGEMENT </a>
                        <a href="/">COST ACCOUNTING & MANAGEMENT CONTROL</a>
                      </div>{" "}
                      <div className="academic-right-single-item">
                        <a href="/">INNOVATION</a>
                        <a href="/">ORGANISATIONAL BEHAVIOUR</a>
                      </div>{" "}
                      <div className="academic-right-single-item">
                        <a href="/">NEGOTIATION</a>
                        <a href="/">ARCHITECTURE</a>
                      </div>{" "}
                      <div className="academic-right-single-item">
                        <a href="/">LAW</a>
                        <a href="/">OTHERS</a>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Hero;
