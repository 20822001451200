import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import "../components/Home/assets/css/header.css";
import mainLogo from "./logo.svg";
const Header = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <header className="header-main-area">
      <div className="container">
        <div className="header-inner-wrap">
          <div className="header-logo">
            <Link to="/">
              <img src={mainLogo} alt="" />
            </Link>
          </div>
          <div className="menuToggle" onClick={() => setToggle(!toggle)}>
            {toggle ? (
              <div className="navbar_mobile_close remove">
                <span></span>
                <span></span>
                <span></span>
              </div>
            ) : (
              <div className=" navbar_mobile_menu_bar">
                <span></span>
                <span></span>
                <span></span>
              </div>
            )}
          </div>
          <nav
            className={
              toggle ? "navbar_mobile_menu show" : "navbar_mobile_menu hide"
            }
          >
            <div className="header-navbar">
              <ul>
                <li>
                  <NavLink
                    exact
                    activeClassName="navbar__link--active"
                    className="navbar__link"
                    to="/"
                  >
                    Home
                  </NavLink>
                </li>
                {/* <li>
                  <a
                    exact
                    activeClassName="navbar__link--active"
                    className="navbar__link"
                    href="#Techer_Panel"
                  >
                    Techer Panel
                  </a>
                </li>
                <li>
                  <a
                    exact
                    activeClassName="navbar__link--active"
                    className="navbar__link"
                    href="#Student_Panel"
                  >
                    Student Panel
                  </a>
                </li> */}
                <li>
                  <a
                    exact
                    activeClassName="navbar__link--active"
                    className="navbar__link"
                    href="https://maacsolutions.com/contact"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact Us
                  </a>
                </li>
                {/* <li>
                  <a
                    exact
                    activeClassName="navbar__link--active"
                    className="navbar__link"
                    href="#course_ware"
                  >
                    <img src={courseWare} alt="" />
                  </a>
                </li> */}
                <li>
                  <div exact className="navbar__link header-login-btn">
                    <Dropdown className="header-login-dropdown">
                      <Dropdown.Toggle id="dropdown-basic">
                        Login
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>Teacher</Dropdown.Item>
                        <span className="font-smaller"> Or</span>
                        <Dropdown.Item>Students</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </li>
                {/* <li>
                  <NavLink
                    exact
                    className="navbar__link header-registration-btn"
                    to="/registration"
                  >
                    Registration
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
