import React from "react";
import Slider from "react-slick";
import "../assets/css/feature.css";
import featue4 from "./img/1.png";
// import featue10 from "../assets/image/ClientLogo/Classpay Logo v.png";
import featue8 from "./img/10.png";
import featue12 from "./img/11.png";
import featue13 from "./img/12.png";
import featue14 from "./img/13.png";
import featue15 from "./img/14.png";
// import featue16 from "./img/15.png";
import featue5 from "./img/2.png";
import featue3 from "./img/3.png";
import featue6 from "./img/4.png";
import featue2 from "./img/5.png";
import featue1 from "./img/6.png";
import featue7 from "./img/7.png";
// import featue11 from "./img/8.png";
import featue9 from "./img/9.png";

const Feature = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    // slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          // slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          // slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          // slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <div className="feature-main-area">
      <div className="container">
        <div className="feature-inner-area">
          <div className="feature-items-wrap">
            <div className="our-customer">
              <div>
                {/* <h6>Using This Product</h6> */}
                <h4>Game Coverage</h4>
              </div>
            </div>
            <Slider {...settings} className="slider-wrap">
              <div className="feature-single-item">
                <img src={featue1} alt="" />
              </div>
              <div className="feature-single-item">
                <img src={featue2} alt="" />
              </div>
              <div className="feature-single-item">
                <img src={featue3} alt="" />
              </div>
              <div className="feature-single-item">
                <img src={featue4} alt="" />
              </div>
              <div className="feature-single-item">
                <img src={featue5} alt="" />
              </div>
              <div className="feature-single-item">
                <img src={featue6} alt="" />
              </div>
              <div className="feature-single-item">
                <img src={featue7} alt="" />
              </div>
              <div className="feature-single-item">
                <img src={featue8} alt="" />
              </div>
              <div className="feature-single-item">
                <img src={featue9} alt="" />
              </div>
              {/* <div className="feature-single-item">
                <img src={featue10} alt="" />
              </div> */}
              {/* <div className="feature-single-item">
                <img src={featue11} alt="" />
              </div> */}
              <div className="feature-single-item">
                <img src={featue12} alt="" />
              </div>
              <div className="feature-single-item">
                <img src={featue13} alt="" />
              </div>
              <div className="feature-single-item">
                <img src={featue14} alt="" />
              </div>
              <div className="feature-single-item">
                <img src={featue15} alt="" />
              </div>
              {/* <div className="feature-single-item">
                <img src={featue16} alt="" />
              </div> */}
            </Slider>
            {/* <Carousel
              autoPlay={true}
              showArrows={false}
              infiniteLoop={true}
              selectedItem={6}
            >
              <div className="feature-single-item">
                <img src={featue1} alt="" />
              </div>
              
              <div className="feature-single-item">
                <img src={featue3} alt="" />
              </div>
              <div className="feature-single-item">
                <img src={featue4} alt="" />
              </div>
              <div className="feature-single-item">
                <img src={featue5} alt="" />
              </div>
              <div className="feature-single-item">
                <img src={featue6} alt="" />
              </div>
            </Carousel> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
