import React, { useEffect } from "react";
import { useLocation } from "react-router";
import MainFooter from "../../Common/footer/MainFooter";
import Header from "../../Common/Header";
import BusinessClass from "./BusinessClass/BusinessClass";
import CallToAction from "./CallToAction/CallToAction";
import Feature from "./feature/Feature";
import Hero from "./Hero/Hero";
import Service from "./Services/Service";

const Home = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="App home-page-main-bg">
      <Header />
      <Hero />
      <BusinessClass />
      <Service />
      <Feature />
      <CallToAction />
      <MainFooter />
      {/* <Footer /> */}
    </div>
  );
};

export default Home;
